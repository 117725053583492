<template>
  <div>
    <AppTable
      store-module="videos"
      :table-fields="tableFields"
      :filters="filters"
      :actions="actions"
      :form-fields="formFields"
      :table-header="tableHeader"
    />
  </div>
</template>

<script>
import AppTable from "@/components/table/AppTable.vue";
import i18n from "@/libs/i18n/index";

export default {
  name: "Videos",
  components: {
    AppTable,
  },
  data() {
    return {
      tableFields: [
        { key: "edit_delete", label: i18n.t("edit_delete") || "Edit/Delete" },
        { key: "title_ar", label: i18n.t("title_ar") || "title" },
        {
          key: "link",
          label: i18n.t("video_thumbnail") || "Video Thumbnail",
        },
      ],
      filters: [{ name: "createdAt", componentType: "AppDatePicker" }],
      actions: [
        { label: "Edit", variant: "primary", event: "edit" },
        { label: "Delete", variant: "danger", event: "delete" },
      ],
      formFields: [
        {
          component: "AppInput",
          attrs: {
            vModel: "title_ar",
            type: "text",
            required: true,
            label: i18n.t("title_ar") || "Title in Arabic",
          },
        },
        {
          component: "AppInput",
          attrs: {
            vModel: "alt_ar",
            type: "text",
            required: true,
            label: i18n.t("alt_ar") || "Alt in Arabic",
          },
        },
        {
          component: "AppInput",
          attrs: {
            vModel: "link",
            type: "url",
            required: true,
            label: i18n.t("link") || "Link",
          },
        },
        {
          component: "AppCheckboxes",
          attrs: {
            id: "in_home",
            vModel: "in_home",
            textField: "name",
            valueField: "value",
            labelClass: "my-0",
            options: [
              {
                name: i18n.t("display_in_home_page") || "In Home",
                value: 1,
              },
            ],
          },
        },
        {
          component: "AppCheckboxes",
          attrs: {
            id: "in_gallery",
            vModel: "in_gallery",
            textField: "name",
            valueField: "value",
            options: [
              {
                name: i18n.t("display_in_gallery_page") || "In Gallery",
                value: 1,
              },
            ],
          },
        },
      ],
      tableHeader: {
        addNewBtn: true,
        multiKeySearch: true,
      },
    };
  },
};
</script>
